import React, {useRef} from 'react';
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {BLOCKS, INLINES} from '@contentful/rich-text-types'
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {renderRichText} from "gatsby-source-contentful/rich-text";
import Moment from "moment";

function BlogPost({data}) {

    const videoRef = useRef(null);

    const post = data.contentfulBlogPost;

    // console.log(post);

    const image = {
        backgroundImage: 'url('+(post.image && post.image.url)+')'
    }

    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const { gatsbyImageData, description, mimeType, file } = node.data.target

                if (mimeType?.startsWith('video')) {
                    return <video
                        ref={videoRef}
                        className={`block z-10 w-100`}
                        controls={true}
                        autoPlay={false}
                        loop={false}
                        playsInline={true}
                        disablePictureInPicture={false}
                        poster={(post.image && post.image.url)}
                    >
                        <source src={file.url} type='video/mp4'/>
                    </video>
                }

                return (
                    <GatsbyImage
                        image={getImage(gatsbyImageData)}
                        alt={description}
                    />
                )
            },
            [INLINES.HYPERLINK]: (node, children) => {
                const {uri} = node.data
                return (
                    <a href={uri} target={'_blank'} rel={'noreferrer'}>
                        {children}
                    </a>
                )
            },
        },
    }

    return (
        <div>
            <Helmet>
                <title>Inter Protocol - {post.title}</title>
                <meta name="description" key="description" content={post.excerpt} />
                <meta name="title" key="title" content={post.title} />
                <meta property="og:title" key="og:title" content={post.title} />
                <meta
                    property="og:description"
                    key="og:description"
                    content={post.excerpt}
                />
                <meta
                    property="og:image"
                    key="og:image"
                    content={post.image.url}
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@inter_protocol" />
                <meta name="twitter:title" content={post.title} />
                <meta name="twitter:description" content={post.excerpt} />
                <meta name="twitter:image" content={post.image.url} />

            </Helmet>
            <Header/>
            <div className={'section pt-40 blog-article'}>
                <div className={'container container-slim'}>

                    <h1>{post.title}</h1>
                    <div className={'date'}>{Moment(post.publishedAt).format('MMMM DD ,YYYY')}</div>

                    <div className={'image'} style={image}/>

                    <div className={'blog-text'}>
                        {renderRichText(post.body, options)}
                    </div>
                </div>
            </div>
            <div className={'section footer-section'}>
                <Footer/>
            </div>
        </div>
    );
}

export default BlogPost;

export const query = graphql`
    query BlogPostBySlug(
        $slug: String!
    ) {
        contentfulBlogPost(slug: { eq: $slug }) {
            id
            image {
                url
                title
            }
            thumbnail {
                url
                title
            }
            title
            slug
            publishedAt
            excerpt
            body {
                raw
                references {
                    contentful_id
                    mimeType
                    title
                    description
                    gatsbyImageData(width: 1000)
                    __typename
                    file {
                        url
                    }
                }
            }
        }
    }
`
